<template>
  <div class="fragment">
    <div class="customs-info site-custom-scroll-y">
      <vue-custom-scrollbar class="customs-info-scroll" :settings="settings">
        <div class="customs-info__list">
          <div class="customs-info__item"
               v-for="(item, index) in $store.getters.getCustomLimits"
               :key="index"
          >
            <div class="customs-info__left">
              <div class="customs-info__name">
                <ValueHelper
                    :value="item.country"
                    :deep="'name'"
                />
              </div>
              <div class="customs-info__code">
                {{ item.country.code_iso_2 }}
              </div>
            </div>
            <div class="customs-info__content">
              <div class="section-label large text-decoration-underline">
                EN
              </div>
              <p v-html="item.customs_info_en">

              </p>
              <div class="section-label mt-4 large text-decoration-underline">
                RU
              </div>

              <p v-html="item.customs_info_ru">

              </p>
              <div class="section-label mt-4 large text-decoration-underline">
                UA
              </div>

              <p v-html="item.customs_info_ua">

              </p>
            </div>
          </div>
        </div>
      </vue-custom-scrollbar>
    </div>

    <div class="table-bottom-btn">
      <div class="table-bottom-btn__inner">
        <ShowMore
            v-if="$store.getters.getCustomLimitsCommonList.next_page_url !== null && $store.getters.getCustomLimits.length > 0"
            @click.native="$emit('showMore')"
            v-bind:class="{'disabled-btn' : $store.getters.getNextCustomLimitsPage}"
            :count="$store.getters.getCustomLimitsCommonList.total - $store.getters.getCustomLimitsForPage < $store.getters.getCustomLimitsForPage ?
                  $store.getters.getCustomLimitsCommonList.total - $store.getters.getCustomLimitsForPage:
                  $store.getters.getCustomLimitsForPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ShowMore from "../../../../../coreComponents/Buttons/ShowMore/ShowMore";
import vueCustomScrollbar from 'vue-custom-scrollbar';
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";

export default {
  name: "CustomsInfoList",
  components: {
    ValueHelper,
    ShowMore,
    vueCustomScrollbar,
  },

  data() {
    return {
      list: [
        {
          name: 'Andora',
          code: 'AD',
          content:
              `<b>Метод оценки GIF (стоимость + страхование + доставка)</b><br><br>
                      <b>Таможенная пошлина</b><br>
                      Ставка на большинство товаров лежит в диапазоне от 0 до 17% от сумы CIF<br><br>
                      <b>Налог с продажи</b><br>
                      4.5% от суммы CIF<br><br>
                      <b>Порог для беспошлинного ввоза</b><br>
                      Посылки со значением FOB (стоимость товаров без учета доставки и страхования) до 220EUR (около 234USD) не облагается ни таможенными пошлинами, ни налогом с продаж
              `,
        },
        {
          name: 'United Arab Emirates',
          code: 'AE',
          content:
              `The valuation method is <b>CIF</b> (cost + insurance + delivery).<br><br>
                      <b>Customs duty</b><br>
                      From 0 to 100% of the amount of CIF. With an average value of 4.61%. Some products may be imported duty free (such as laptops and other electronic products).<br><br>
                      <b>Sales tax</b><br>
                      No sales tax.<br><br>
                      <b>Threshold for duty-free import</b><br>
                      Parcels with a CIF value (cost + insurance + delivery) up to <b>1000AED</b> (≈272.3USD) are exempted from customs duties.
            `,
        },
        {
          name: 'Andora',
          code: 'AD',
          content:
              `<b>Метод оценки GIF (стоимость + страхование + доставка)</b><br><br>
                      <b>Таможенная пошлина</b><br>
                      Ставка на большинство товаров лежит в диапазоне от 0 до 17% от сумы CIF<br><br>
                      <b>Налог с продажи</b><br>
                      4.5% от суммы CIF<br><br>
                      <b>Порог для беспошлинного ввоза</b><br>
                      Посылки со значением FOB (стоимость товаров без учета доставки и страхования) до 220EUR (около 234USD) не облагается ни таможенными пошлинами, ни налогом с продаж
              `,
        },
        {
          name: 'United Arab Emirates',
          code: 'AE',
          content:
              `The valuation method is <b>CIF</b> (cost + insurance + delivery).<br><br>
                      <b>Customs duty</b><br>
                      From 0 to 100% of the amount of CIF. With an average value of 4.61%. Some products may be imported duty free (such as laptops and other electronic products).<br><br>
                      <b>Sales tax</b><br>
                      No sales tax.<br><br>
                      <b>Threshold for duty-free import</b><br>
                      Parcels with a CIF value (cost + insurance + delivery) up to <b>1000AED</b> (≈272.3USD) are exempted from customs duties.
            `,
        },
        {
          name: 'Andora',
          code: 'AD',
          content:
              `<b>Метод оценки GIF (стоимость + страхование + доставка)</b><br><br>
                      <b>Таможенная пошлина</b><br>
                      Ставка на большинство товаров лежит в диапазоне от 0 до 17% от сумы CIF<br><br>
                      <b>Налог с продажи</b><br>
                      4.5% от суммы CIF<br><br>
                      <b>Порог для беспошлинного ввоза</b><br>
                      Посылки со значением FOB (стоимость товаров без учета доставки и страхования) до 220EUR (около 234USD) не облагается ни таможенными пошлинами, ни налогом с продаж
              `,
        },
        {
          name: 'United Arab Emirates',
          code: 'AE',
          content:
              `The valuation method is <b>CIF</b> (cost + insurance + delivery).<br><br>
                      <b>Customs duty</b><br>
                      From 0 to 100% of the amount of CIF. With an average value of 4.61%. Some products may be imported duty free (such as laptops and other electronic products).<br><br>
                      <b>Sales tax</b><br>
                      No sales tax.<br><br>
                      <b>Threshold for duty-free import</b><br>
                      Parcels with a CIF value (cost + insurance + delivery) up to <b>1000AED</b> (≈272.3USD) are exempted from customs duties.
            `,
        },
      ],

      settings: {
        suppressScrollY: false,
        suppressScrollX: true,
        wheelPropagation: false,
        wheelSpeed: 0.5,
        swipeEasing: true,
        scrollXMarginOffset: 16,
      },
    }
  }
}
</script>

<style lang="scss">
@import "../../../../../../scss/colors";
@import "../../../../../../scss/mixins/mixins";

.customs-info-scroll {
  max-height: calc(100vh - 190px);
  overflow: hidden;

  @include for-1600 {
    max-height: calc(100vh - 400px);
  }

  @include for-1200 {
    max-height: calc(100vh - 370px);
  }

  @include for-550 {
    max-height: calc(100vh - 290px);
  }
}

.customs-info {
  width: calc(100% + 10px);

  &__list {
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 5px;
    width: calc(100% - 10px);
  }

  &__item {
    display: flex;
    padding: 24px 48px;

    &:nth-child(even) {
      background: $mainBgOpacity05;
    }

    @include for-992 {
      flex-direction: column;
      padding: 15px;
    }
  }

  &__left {
    max-width: 230px;
    width: 100%;
    padding-right: 15px;

    @include for-992 {
      padding-right: 0;
      margin-bottom: 15px;
    }
  }

  &__name {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: $black;
    margin-bottom: 5px;

    @include for-1600 {
      font-size: 20px;
      line-height: normal;
    }
  }

  &__code {
    font-size: 14px;
    line-height: 16px;
    color: $borderBrown;
  }

  &__content {
    width: 100%;

    p {
      margin: 0;


      @include for-1600 {
        font-size: 14px;
        line-height: normal;
      }
    }
  }
}

.customs-info__content a {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #8f7a61;
  transition: .3s;
}


</style>
